import common from "./common";
import development from "./development";
import feedbackForm from "../../../shared/locales/feedbackForm";
import headerSlider from "./headerSlider";
import nav from "../../../shared/locales/nav";
import projects from "./projects";
import services from "./services";

export const locales = {
  ...common,
  feedbackForm,
  nav,
  headerSlider,
  projects,
  services,
  development
};
