let previousLabel = document.querySelectorAll(".project-tabs__label")[0];
document.querySelectorAll(".project-tabs__checkbox").forEach((element) => {
  element.addEventListener("click", (e) => {
    document.querySelectorAll(".project-tabs__label").forEach((el) => el.classList.remove("project-tabs__label--line-hide"));
    previousLabel.classList.add("project-tabs__label--line-hide");
    const thisLabel = previousLabel;
    setTimeout(() => {
      thisLabel.classList.remove("project-tabs__label--line-hide");
    }, 500);
    previousLabel = e.target.labels[0];
    document.querySelectorAll(".project-tabs__item").forEach((item) => {
      if (item.dataset.projectItem === e.target.dataset.projectCheckbox) {
        item.classList.add("project-tabs__item--show");
      } else {
        item.classList.remove("project-tabs__item--show");
      }
    });
    e.target.dataset.projectCheckbox;
  });
});
