import Cookies from "js-cookie";

function cookieFunction() {
  const banner = document.querySelector(".cookie-banner");
  const bannerDetailsButton = document.querySelector(".cookie-banner-small__button-details");
  const smallBanner = document.querySelector(".cookie-banner-small");
  const largeBanner = document.querySelector(".cookie-banner-large");
  const acceptAllCookie = document.querySelector(".cookie-banner-small__button");
  const choseAllCookie = document.querySelector(".cookie-banner-large__chose-all");
  const saveCookie = document.querySelector(".cookie-banner-large__save");
  const saveFirstCookie = document.querySelector(".cookie-banner-small__save-first");

  const cookieCheckbox = document.querySelectorAll(".cookie-banner-large__checkbox-input");
  cookieCheckbox[0].checked = true;

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  let hasCookie = false;

  cookieCheckbox.forEach(function (el) {
    if (getCookie(el.name)) {
      hasCookie = true;
      return;
    }
  });

  if (hasCookie) return;
  if (!hasCookie) {
    banner.classList.remove("cookie-banner--hide");
  }

  bannerDetailsButton.onclick = () => {
    smallBanner.classList.add("cookie-banner-small--hide");
    largeBanner.classList.add("cookie-banner-large--show");
  };

  acceptAllCookie.onclick = () => {
    banner.classList.add("cookie-banner--hide");
    cookieCheckbox.forEach(function (el) {
      Cookies.set(el.name, "true", { expires: 365, path: "/" });
    });
  };

  choseAllCookie.onclick = () => {
    cookieCheckbox.forEach(function (el) {
      el.checked = true;
    });
  };

  saveCookie.onclick = () => {
    banner.classList.add("cookie-banner--hide");
    cookieCheckbox.forEach(function (el) {
      if (el.checked) {
        Cookies.set(el.name, "true", { expires: 365, path: "/" });
      }
    });
  };

  saveFirstCookie.onclick = () => {
    banner.classList.add("cookie-banner--hide");
    Cookies.set(cookieCheckbox[0].name, "true", { expires: 365, path: "/" });
  };
}

cookieFunction();
