const menuBtnRef = document.querySelector("[data-menu-button]");
const mobileMenuRef = document.querySelector("[data-menu]");
const mobileHederBackground = document.getElementsByName("section-header");

function lockScroll() {
  const html = document.querySelector("html");
  var scrollPosition = [
    self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
    self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  ];
  html.data = { scrollPosition, previousOverflow: html.style.overflow };
  html.style.overflow = "hidden";
  window.scrollTo(scrollPosition[0], scrollPosition[1]);
}

function unlockScroll() {
  const html = document.querySelector("html");
  html.style.overflow = html.data.previousOverflow;
  var scrollPosition = html.data.scrollPosition;
  window.scrollTo(scrollPosition[0], scrollPosition[1]);
}

const sidebarMenuToggle = () => {
  const expanded = menuBtnRef.getAttribute("aria-expanded") === "true" || false;

  if (!expanded) {
    lockScroll();
  } else {
    unlockScroll();
  }

  menuBtnRef.classList.toggle("dropbtn--open");
  menuBtnRef.setAttribute("aria-expanded", !expanded);

  mobileMenuRef.classList.toggle("is-hidden");

  if (!mobileMenuRef.classList.contains("is-hidden")) {
    document.getElementsByTagName("header")[0].classList.add("primary-background-color");
  } else {
    document.getElementsByTagName("header")[0].classList.remove("primary-background-color");
  }
};

document.querySelectorAll("[data-menu-toggle]").forEach((el) => {
  el.addEventListener("click", function () {
    sidebarMenuToggle();
  });
});
