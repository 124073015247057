export default {
  home: {
    en: "Home",
    de: "Start"
  },
  projects: {
    en: "Projects",
    de: "Projekte"
  },
  services: {
    en: "Industries",
    de: "Branchen"
  },
  development: {
    en: "Development",
    de: "Entwicklung"
  },
  portfolio: {
    en: "Portfolio",
    de: "Portfolio"
  },
  contacts: {
    en: "Contacts",
    de: "Kontakt"
  },
  games: {
    en: "Games",
    de: "Spieleliste"
  }
};
