import firstImageMobile from "../../../../img/360/mobile/HeJLove.jpg";
import firstWebpImageMobile from "../../../../img/360/mobile/HeJLove.webp";
import firstImageTablet from "../../../../img/360/tablet/HeJLove.jpg";
import firstWebpImageTablet from "../../../../img/360/tablet/HeJLove.webp";
import firstImageDesktop from "../../../../img/360/desktop/HeJLove.jpg";
import firstWebpImageDesktop from "../../../../img/360/desktop/HeJLove.webp";

import secondImageMobile from "../../../../img/360/mobile/White box.jpg";
import secondWebpImageMobile from "../../../../img/360/mobile/White box.webp";
import secondImageTablet from "../../../../img/360/tablet/White box.jpg";
import secondWebpImageTablet from "../../../../img/360/tablet/White box.webp";
import secondImageDesktop from "../../../../img/360/desktop/White box.jpg";
import secondWebpImageDesktop from "../../../../img/360/desktop/White box.webp";

import thirdImageMobile from "../../../../img/360/mobile/Fraulein.jpg";
import thirdWebpImageMobile from "../../../../img/360/mobile/Fraulein.webp";
import thirdImageTablet from "../../../../img/360/tablet/Fraulein.jpg";
import thirdWebpImageTablet from "../../../../img/360/tablet/Fraulein.webp";
import thirdImageDesktop from "../../../../img/360/desktop/Fraulein.jpg";
import thirdWebpImageDesktop from "../../../../img/360/desktop/Fraulein.webp";

import fourthImageMobile from "../../../../img/360/mobile/CMS.jpg";
import fourthWebpImageMobile from "../../../../img/360/mobile/CMS_op.webp";
import fourthImageTablet from "../../../../img/360/tablet/CMS.jpg";
import fourthWebpImageTablet from "../../../../img/360/tablet/CMS.webp";
import fourthImageDesktop from "../../../../img/360/desktop/CMS.jpg";
import fourthWebpImageDesktop from "../../../../img/360/desktop/CMS.webp";

export const data360 = {
  first: {
    images: {
      imageMobile: firstImageMobile,
      webpImageMobile: firstWebpImageMobile,
      imageTablet: firstImageTablet,
      webpImageTablet: firstWebpImageTablet,
      imageDesktop: firstImageDesktop,
      webpImageDesktop: firstWebpImageDesktop
    },
    link: "http://7th-tec.com/360/HejLove.html",
    title: "HEJLOVE"
  },
  second: {
    images: {
      imageMobile: secondImageMobile,
      webpImageMobile: secondWebpImageMobile,
      imageTablet: secondImageTablet,
      webpImageTablet: secondWebpImageTablet,
      imageDesktop: secondImageDesktop,
      webpImageDesktop: secondWebpImageDesktop
    },
    link: "http://7th-tec.com/360/WhiteBox.html",
    title: "WHITE BOX"
  },
  third: {
    images: {
      imageMobile: thirdImageMobile,
      webpImageMobile: thirdWebpImageMobile,
      imageTablet: thirdImageTablet,
      webpImageTablet: thirdWebpImageTablet,
      imageDesktop: thirdImageDesktop,
      webpImageDesktop: thirdWebpImageDesktop
    },
    link: "http://7th-tec.com/360/FraeuleinMode.html",
    title: "FRÄULEIN"
  },
  fourth: {
    images: {
      imageMobile: fourthImageMobile,
      webpImageMobile: fourthWebpImageMobile,
      imageTablet: fourthImageTablet,
      webpImageTablet: fourthWebpImageTablet,
      imageDesktop: fourthImageDesktop,
      webpImageDesktop: fourthWebpImageDesktop
    },
    link: "http://7th-tec.com/360/CMS.html",
    title: "CMS"
  }
};
