document.querySelectorAll(".mobile-development__toggle").forEach((el) => {
  el.addEventListener("click", function () {
    const input = this;
    if (input.classList.contains("theone")) {
      input.checked = false;
      input.classList.remove("theone");
    } else {
      document.querySelectorAll("mobile-development__toggle").forEach((el) => {
        el.classList.remove("theone");
      });
      input.classList.add("theone");
    }
  });
});
