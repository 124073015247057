const inputs = document.querySelectorAll(".feedback-form__input");

function validateInput(valid, input) {
  if (valid) {
    input.parentElement.classList.add("feedback-form__group--valid");
    input.classList.remove("feedback-form__input--invalid");
    input.parentElement.lastElementChild.hidden = true;
  } else {
    input.parentElement.classList.remove("feedback-form__group--valid");
    input.classList.add("feedback-form__input--invalid");
    input.parentElement.lastElementChild.hidden = false;
  }
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validatePhoneNumber(number) {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(String(number));
}

const inputMessageHandler = (e) => validateInput(e.target.value.length > 1 && e.target.value.length < 500, e.target);

const inputEmailHandler = (e) => validateInput(validateEmail(e.target.value), e.target);

const inputPhoneHandler = (e) => validateInput(validatePhoneNumber(e.target.value), e.target);

const inputNameHandler = (e) => validateInput(e.target.value !== "", e.target);

export const formValid = () => {
  let isValid;
  inputs.forEach((i) => i.classList.remove("feedback-form__input--invalid"));
  inputs.forEach((input) => {
    const name = input.name.toLowerCase();
    if (name === "email" && !validateEmail(input.value)) {
      input.parentElement.lastElementChild.hidden = false;
      input.removeEventListener("input", inputEmailHandler);
      input.classList.add("feedback-form__input--invalid");
      isValid = false;
      input.addEventListener("input", inputEmailHandler);
    } else if (name === "message" && (input.value.length <= 1 || input.value.length >= 500)) {
      input.parentElement.lastElementChild.hidden = false;
      input.removeEventListener("input", inputMessageHandler);
      input.classList.add("feedback-form__input--invalid");
      isValid = false;
      input.addEventListener("input", inputMessageHandler);
    } else if (name === "phone" && !validatePhoneNumber(input.value)) {
      input.parentElement.lastElementChild.hidden = false;
      input.removeEventListener("input", inputPhoneHandler);
      input.classList.add("feedback-form__input--invalid");
      isValid = false;
      input.addEventListener("input", inputPhoneHandler);
    } else if (name === "name" && input.value === "") {
      input.parentElement.lastElementChild.hidden = false;
      input.removeEventListener("input", inputNameHandler);
      input.classList.add("feedback-form__input--invalid");
      isValid = false;
      input.addEventListener("input", inputNameHandler);
    } else {
      isValid = isValid !== false;
      input.parentElement.classList.add("feedback-form__group--valid");
    }
  });
  return isValid;
};
