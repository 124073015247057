import sharedCommon from "../../../shared/locales/common"

export default {
  offers: {
    title: {
      en: "Do you want us to bring your company to a new level?",
      de: "Wie dürfen wir Ihr Unternehmen auf ein neues Level bringen?"
    },
    text: {
      en: "7th Tec is your full-service partner for future-oriented digitization solutions with a focus on VR/AR/360° and web design!",
      de: "7th Tec ist Ihr Full-Service-Partner für zukunftsorientierte Digitalisierungslösungen mit Fokus auf VR/AR/360° und Webdesign!"
    }
  },
  ...sharedCommon
};
