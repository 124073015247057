import Swiper from "../../../../node_modules/swiper/swiper-bundle";

let servicesSwiper;
let mainSwiper;

function setMainSlider() {
  mainSwiper = new Swiper(".main-swiper", {
    slidesPerView: 1,
    loop: true,
    speed: 700,
    loopAdditionalSlides: 1,
    breakpoints: {
      500: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 2.4
      }
    },
    navigation: {
      nextEl: ".main-swiper-button--next",
      prevEl: ".main-swiper-button--prev"
    },
    pagination: {
      el: document.querySelector(".main-swiper-wrapper .swiper-pagination"),
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      }
    }
  });
}

function setServicesSlider() {
  servicesSwiper = new Swiper(".services-swiper", {
    slidesPerView: 1,
    loop: true,
    speed: 700,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 1.3
      }
    },
    pagination: {
      el: document.querySelector(".services-swiper-wrapper .swiper-pagination"),
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      }
    }
  });
}

function addClassMailSlider() {
  let vr = document.querySelector(".main-slider__item--first"),
    web = document.querySelector(".main-slider__item--second"),
    i360 = document.querySelector(".main-slider__item--third"),
    hybrid = document.querySelector(".main-slider__item--fourth"),
    ar = document.querySelector(".main-slider__item--fifth");

  vr.classList.add("main-slider__item--first-vr");
  web.classList.add("main-slider__item--second-web");
  i360.classList.add("main-slider__item--third-360");
  hybrid.classList.add("main-slider__item--fourth-hybrid");
  ar.classList.add("main-slider__item--fifth-game");
}

if (window.innerWidth <= 1024) {
  setMainSlider();
  setServicesSlider();
}

if (window.innerWidth > 1024) {
  addClassMailSlider();
}

window.addEventListener("resize", () => {
  if (window.innerWidth > 1024 && servicesSwiper != undefined) {
    servicesSwiper.destroy();
    servicesSwiper = undefined;
  } else if (window.innerWidth <= 1024 && servicesSwiper == undefined) {
    setServicesSlider();
  }
  if (window.innerWidth > 1024 && mainSwiper != undefined) {
    mainSwiper.destroy();
    mainSwiper = undefined;
    addClassMailSlider();
  } else if (window.innerWidth <= 1024 && mainSwiper == undefined) {
    setMainSlider();
  }
});

// const portfolioSwiper = new Swiper(".portfolio-swiper", {
//   slidesPerView: 1,
//   loop: true,
//   speed: 700,
//   breakpoints: {
//     600: {
//       slidesPerView: 3
//     },
//     1000: {
//       slidesPerView: 5
//     },
//     1025: {
//       slidesPerView: 5,
//       freeMode: {
//         enabled: true,
//         sticky: true
//       },
//       autoplay: {
//         enabled: true,
//         delay: 1,
//         pauseOnMouseEnter: true,
//         disableOnInteraction: false
//       },
//       speed: 2500
//     }
//   }
// });

const prijectsTab = new Swiper(".project-tabs__inputs", {
  slidesPerView: "auto",
  freeMode: true,
  spaceBetween: 40
});

const projects360 = new Swiper(".projects360", {
  slidesPerView: "auto",
  spaceBetween: 40,
  freeMode: true,
  breakpoints: {
    1920: {
      spaceBetween: 85
    }
  },
  pagination: {
    el: document.querySelector(".swiper-object-360 .swiper-pagination"),
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    }
  }
});
