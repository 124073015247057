import "./sass/main.scss";

import "../../img/sprite.svg";

import { locales } from "./locales/main";

// SHARED
import "../../shared/js/common";
import { i18n } from "../../shared/js/i18n";
import "../../shared/js/header";
import "../../shared/js/impressumPopup";
import "../../shared/js/cookieBanner";
import "../../shared/js/lazyload";
import "../../shared/js/sendForm";
import "../../shared/js/navSticky";
import "../../shared/js/sidebar";

// ONLY HOME PAGE
import "./js/mobileAccordion";
import "./js/projectTabs";
import "./js/tabs";
import "./js/360/360";
import "./js/swiper";

i18n(locales);
