export default {
  title: {
    en: "Projects",
    de: "Projekte"
  },
  description: {
    en: "We will show you the most exciting projects of the recent past and how our solutions serve the respective use case accurately!",
    de: "Wir zeigen Ihnen die spannendsten Projekte der jüngeren Vergangenheit und wie unsere Lösungen den jeweiligen Use Case treffsicher bedienen!"
  },
  mazak: {
    en: "Use virtual reality as a tool for industrial product, support and sales training, sales meetings and more! Independent of location and accessible at any time! Ideal for industrial large-scale systems, such as cut laser machines! In addition to the tour, interaction with the systems and the environment is also possible - support training, sales and many other scenarios can be handled!",
    de: "Nutzen Sie Virtual Reality als Werkzeug für innerbetrieblich Produkt-, Support- und Vertriebsschulungen, Sales Meetings und mehr! Standortunabhängig und jederzeit zugänglich! Ideal für industrielle Großanlagen, etwa Schnittlasermaschinen! Neben der Besichtigung ist auch die Interaktion mit den Anlagen und der Umgebung möglich - so können Supportschulungen, Kaufabschlüsse und viele weitere Szenarien bedient werden!"
  },
  vil: {
    en: "Experience educational content - that is the big plus of VIL, our mobile plug & play system for modern VR/AR-based teaching! Whether in school, university or industrial training and further education: VIL is the intuitive tool for VR and AR-based teaching content! Technological affinity or previous experience is not necessary - our streaming solution enables every supervisor or teacher, lessons, workshops, conferences and co. to be supplemented by VR and AR!",
    de: "Bildungsinhalte erleben - das ist das große Plus von VIL, einem von uns entwickelten mobilen Plug&Play-System zur modernen Wissensvermittlung durch VR und AR! Ob in der schulischen, universitären oder innerbetrieblichen Aus- und Weiterbildung: VIL ist das intuitive Werkzeug für VR- und AR-basierte Lehrinhalte! Technikaffinität oder Vorefahrung ist nicht notwendig - unsere Streaminglösung befähigt jede Betreuungs- oder Lehrkraft, Unterrichtsstunden, Workshops, Konferenzen und co. durch VR und AR zu ergänzen!"
  },
  crown: {
    en: "Taylor made web design! In addition to virtual worlds, we also build your website for you! Implemented interaction options and VR/AR widgets literally turn your website into an experience! Discover here, how virtual house tours can be embedded into your website, before the house has even been built!",
    de: "Webdesign nach Maß! Neben virtuellen Welten bauen wir Ihnen auch Ihre Internetpräsenz! Implementierte Interaktionsmöglichkeiten und VR-/AR-Module machen Ihren Web-Auftritt buchstäblich zu einem Erlebnis! Sehen Sie hier, wie virtuelle Hausbesichtigungen in eine Website eingebettet werden, bevor der erste Spatenstich stattgefunden hat!"
  },
  360: {
    en: "Use virtual reality as a tool for industrial product, support and sales training, sales meetings and more! Independent of location and accessible at any time! Ideal for industrial large-scale system.",
    de: "Nutzen Sie Virtual Reality als Werkzeug für Industrieprodukt-, Support- und Verkaufsschulungen, Verkaufsmeetings und mehr! Ortsunabhängig und jederzeit erreichbar! Ideal für industrielle Großanlagen."
  },
  button: {
    en: "Watch video!",
    de: "Video ansehen!"
  }
};
