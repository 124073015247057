function openCard(evt, currentStep) {
  var i, tabcontent;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  const tabsButton = document.querySelectorAll(".tabs__button");
  const tabsImg = document.querySelectorAll(".tabcontent__step");
  for (i = 0; i < tabsButton.length; i++) {
    tabsButton[i].className = tabsButton[i].className.replace(" active", "");
    tabsImg[i].className = tabsImg[i].className.replace(" tabcontent__step--active", "");
  }

  var imgStepElem = document.getElementById("img-step" + currentStep);
  imgStepElem.classList.add("tabcontent__step--active");

  document.getElementById("step" + currentStep).style.display = "block";
  evt.className += " active";
}

openCard(document.querySelectorAll(".tabs__button")[0], 1);

document.querySelectorAll(".tabs__button").forEach((el, i) => {
  el.addEventListener("click", function () {
    openCard(el, i + 1);
  });
});
