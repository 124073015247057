import { data360 } from "./data";

const projects360Items = document.querySelectorAll("[data-projects360]");
const projects360Images = document.querySelectorAll("[data-projects-image]");
const projects360link = document.querySelector("[data-projects-link360]");
const projects360title = document.querySelector("[data-projects-title360]");

let previousItem;

projects360Items.forEach(function (i) {
  i.addEventListener("click", (e) => {
    projects360Items.forEach((item) => {
      if (item === i) item.classList.add("active");
      if (item !== i) item.classList.remove("active");
    });
    projects360link.href = data360[e.currentTarget.dataset.projects360].link;
    projects360title.innerHTML = data360[e.currentTarget.dataset.projects360].title;
    if (previousItem !== i) {
      document.querySelector(".projects360__img-wrapper").classList.add("projects360__animate");
      setTimeout(() => {
        document.querySelector(".projects360__img-wrapper").classList.remove("projects360__animate");
      }, 1000);
    }
    projects360Images.forEach((el) => {
      if (el.srcset) {
        el.srcset = data360[e.currentTarget.dataset.projects360].images[el.dataset.projectsImage];
      } else if (el.src) {
        el.src = data360[e.currentTarget.dataset.projects360].images[el.dataset.projectsImage];
      }
      el.dataset.projectsImage;
    });
    e.currentTarget.dataset.projects360;
    previousItem = i;
  });
});
