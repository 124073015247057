const impressumPopup = () => {
  document.querySelectorAll("[data-agb]").forEach(function (e) {
    e.addEventListener("click", () => {
      document.querySelector(".impressum-popup").classList.add("impressum-popup--show");
    });
  });
  document.querySelector(".impressum-popup__close-button").addEventListener("click", () => {
    document.querySelector(".impressum-popup").classList.remove("impressum-popup--show");
  });
};

impressumPopup();
