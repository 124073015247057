export default {
  headline: {
    en: "Immersive technologies create added value for almost every individual and every industry!",
    de: "Immersive Technologien stiften Mehrwert für nahezu jede Einzelperson und jede Branche!"
  },
  title: {
    en: "Development",
    de: "Entwicklung"
  },
  description: {
    en: "Your way to an immersive product – These are the phases your project is going through from the original idea to the final delivery!",
    de: "Ihr Weg zum immersiven Produkt – diese Phasen durchläuft Ihr Projekt von der Idee bis zur Auslieferung!"
  },
  steps: {
    1: {
      title: {
        en: "Analysis",
        de: "Analyse"
      },
      text: {
        en: "We analyze your demands - what is desired, which technology can create which added value and bring your company to the next level?",
        de: "Wir analysieren Ihren Bedarf – Was ist gewünscht, welche Technologie kann welchen Mehrwert stiften und Ihr Unternehmen gezielt nach vorne bringen?"
      },
      step: {
        en: "Step 1",
        de: "Schritt 1"
      }
    },
    2: {
      title: {
        en: "Delineation",
        de: "Skizzierung"
      },
      text: {
        en: "We outline the project and its milestones for you and run through feedback loops according to your ideas!",
        de: "Wir skizzieren das Projekt und seine Meilensteine für Sie und durchlaufen dabei Feedbackschleifen gemäß Ihrer Vorstellungen!"
      },
      step: {
        en: "Step 2",
        de: "Schritt 2"
      }
    },
    3: {
      title: {
        en: "Conception",
        de: "Konzeption"
      },
      text: {
        en: "We convert the sketch into a more concrete concept and define the final technology arrangement - what is needed, what is the goal?",
        de: "Wir überführen die Skizze in ein konkreteres Konzept und definieren das finale Technologiearrangement – was wird benötigt, was ist das Ziel?"
      },
      step: {
        en: "Step 3",
        de: "Schritt 3"
      }
    },
    4: {
      title: {
        en: "Task Definition",
        de: "Aufgabenbeschreibung"
      },
      text: {
        en: "From the concept we derive a concrete and reliable timetable for the course of the project.",
        de: "Vom Konzept leiten wir einen konkreten und belastbaren Fahrplan für den Projektverlauf ab."
      },
      step: {
        en: "Step 4",
        de: "Schritt 4"
      }
    },
    5: {
      title: {
        en: "Design and Prototyping",
        de: "Design- und Prototypentwicklung"
      },
      text: {
        en: "In this phase the real creative act begins - the first design studies and prototypes are being created.",
        de: "In dieser Phase beginnt der eigentliche kreative Akt – die ersten Designstudien und Prototypen werden erstellt."
      },
      step: {
        en: "Step 5",
        de: "Schritt 5"
      }
    },
    6: {
      title: {
        en: "Software Development",
        de: "Softwareentwicklung"
      },
      text: {
        en: "The design studies are being embedded in the framework of our software.",
        de: "Die Designstudien werden in den Kontext einer Softwareprogrammierung eingebettet."
      },
      step: {
        en: "Step 6",
        de: "Schritt 6"
      }
    },
    7: {
      title: {
        en: "Software Testing",
        de: "Testen"
      },
      text: {
        en: "The symbiosis of design and software code is optimized and tested in several correction loops.",
        de: "Die Symbiose aus Design und Software-Code wird in mehreren Korrekturschleifen optimiert und getestet."
      },
      step: {
        en: "Step 7",
        de: "Schritt 7"
      }
    },
    8: {
      title: {
        en: "Implementation and Integration",
        de: "Implementierung und Integration"
      },
      text: {
        en: "The preliminary product is delivered and, if necessary, adapted and embedded in an existing context (existing website, previous VR productions of the customer, ...). ",
        de: "Das vorläufige Endprodukt wird ausgeliefert und ggf. an einen vorhandenen Kontext (bestehende Website, vorherige VR-Produktionen des Kunden, ...) angepasst und eingebettet."
      },
      step: {
        en: "Step 8",
        de: "Schritt 8"
      }
    },
    9: {
      title: {
        en: "Maintenance",
        de: "Wartung"
      },
      text: {
        en: "As a full-service partner, after-sales support and maintenance are also at the center of our efforts. We are there for you when you need help! ",
        de: "Als Full-Service-Partner steht After Sales Support und Wartung für uns ebenfalls im Zentrum unserer Bemühungen. Wir sind für Sie da, wenn Sie Hilfe benötigen!"
      },
      step: {
        en: "Step 9",
        de: "Schritt 9"
      }
    }
  }
};
