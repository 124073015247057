export function i18n(locales) {
  const selectedLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "de";

  (function () {
    this.I18n = function (defaultLang) {
      var lang = defaultLang || selectedLang;
      this.language = lang;

      (function (i18n) {
        i18n.contents = locales;
        i18n.contents.prop = function (key) {
          var result = this;
          var keyArr = key.split(".");
          for (var index = 0; index < keyArr.length; index++) {
            var prop = keyArr[index];
            result = result[prop];
          }
          return result;
        };
        i18n.localize();
      })(this);
    };

    this.I18n.prototype.hasCachedContents = function () {
      return this.contents !== undefined;
    };

    this.I18n.prototype.lang = function (lang) {
      if (typeof lang === "string") {
        this.language = lang;
      }
      this.localize();
      return this.language;
    };

    this.I18n.prototype.localize = function () {
      var contents = this.contents;
      if (!this.hasCachedContents()) {
        return;
      }
      var dfs = function (node, keys, results) {
        var isLeaf = function (node) {
          for (var prop in node) {
            if (node.hasOwnProperty(prop)) {
              if (typeof node[prop] === "string") {
                return true;
              }
            }
          }
        };
        for (var prop in node) {
          if (node.hasOwnProperty(prop) && typeof node[prop] === "object") {
            var myKey = keys.slice();
            myKey.push(prop);
            if (isLeaf(node[prop])) {
              results.push(
                myKey.reduce(function (previousValue, currentValue, currentIndex, array) {
                  return previousValue + "." + currentValue;
                })
              );
            } else {
              dfs(node[prop], myKey, results);
            }
          }
        }
        return results;
      };
      var keys = dfs(contents, [], []);
      for (var index = 0; index < keys.length; index++) {
        var key = keys[index];
        if (contents.prop(key).hasOwnProperty(this.language)) {
          if (document.querySelectorAll('[data-i18n="' + key + '"]').length >= 1) {
            document.querySelectorAll('[data-i18n="' + key + '"]').forEach((el) => {
              el.innerHTML = contents.prop(key)[this.language];
            });
          } else if (document.querySelectorAll('[data-i18n-placeholder="' + key + '"]').length >= 1) {
            document.querySelectorAll('[data-i18n-placeholder="' + key + '"]').forEach((el) => {
              el.placeholder = contents.prop(key)[this.language];
            });
          }
        } else {
          if (document.querySelectorAll('[data-i18n="' + key + '"]').length >= 1) {
            document.querySelectorAll('[data-i18n="' + key + '"]').forEach((el) => {
              el.innerHTML = contents.prop(key)["de"];
            });
          } else if (document.querySelectorAll('[data-i18n-placeholder="' + key + '"]').length >= 1) {
            document.querySelectorAll('[data-i18n-placeholder="' + key + '"]').forEach((el) => {
              el.placeholder = contents.prop(key)["de"];
            });
          }
        }
      }
    };
  }.apply(window));

  const localization = () => {
    const i18n = new I18n();
    i18n.localize();
    document.querySelectorAll(`.lang .${selectedLang}`).forEach((el) => {
      el.classList.add("lang__selected");
    });

    document.querySelectorAll(".lang .en").forEach((el) => {
      el.addEventListener("click", function () {
        i18n.lang("en");
        localStorage.setItem("lang", "en");
        selectLang(this);
      });
    });
    document.querySelectorAll(".lang .de").forEach((el) => {
      el.addEventListener("click", function () {
        i18n.lang("de");
        localStorage.setItem("lang", "de");
        selectLang(this);
      });
    });

    function selectLang(picker) {
      document.querySelectorAll(".lang button").forEach((el) => {
        el.classList.remove("lang__selected");
      });
      picker.classList.add("lang__selected");
    }
  };

  localization();
}
