import { formValid } from "./validation";

const feedbackForm = document.querySelector(".feedback-form");

feedbackForm.addEventListener("submit", function (e) {
  e.preventDefault();
  var data = new FormData(feedbackForm);
  formValid() &&
    fetch("http://7th-tec.com/mail.php", {
      method: "POST",
      mode: "cors",
      body: data
    }).then((response) => {
      if (response.ok) {
        feedbackForm.classList.add("feedback-form--hidden");
        document.querySelector(".contact__text--after-send").classList.add("contact__text--after-send-success");
        document.querySelector(".contact__text--before-send").classList.add("contact__text--before-send-success");
        document.querySelector(".contact__form-wrapper--right").classList.add("contact__form-wrapper--right-submit-form");
        document.querySelector(".contact__form-wrapper--left").classList.add("contact__form-wrapper--left-after-send");
      }
      if (!response.ok) {
        const errorButton = document.querySelector(".notification__error-button");
        const onClickButton = () => {
          feedbackForm.reset();
          document.querySelector('[name="Name"]').focus();
          document.querySelector(".notification--error").classList.add("notification--hidden");
        };
        document.querySelector(".notification--error").classList.remove("notification--hidden");
        errorButton.addEventListener("click", onClickButton);
        setTimeout(() => {
          errorButton.removeEventListener("click", onClickButton);
          document.querySelector(".notification--error").classList.add("notification--hidden");
        }, 5000);
      }
    });
  return false;
});
