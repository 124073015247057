export default {
  title: {
    en: "Contact",
    de: "Kontakt"
  },
  subtitle: {
    beforeSend: {
      en: "We are happy to get in contact!",
      de: "Wir freuen uns auf Ihre Anfrage!"
    },
    afterSend: {
      en: "Thank you for getting in touch! One of our colleagues will get back in touch with you soon! Have a great day!",
      de: "Vielen Dank für Ihre Kontaktaufnahme! Einer unserer Kollegen wird sich in Kürze bei Ihnen melden! Wir wünschen Ihnen einen wunderbaren Tag!"
    }
  },
  name: {
    placeholder: {
      en: "NAME*",
      de: "NAME*"
    },
    invalid: {
      en: "Please check your name",
      de: "Bitte prüfen Sie Ihre name"
    }
  },
  phone: {
    placeholder: {
      en: "PHONE*",
      de: "TELEFON*"
    },

    invalid: {
      en: "Please check your Phone Number",
      de: "Bitte prüfen Sie Ihre Telefonnummer"
    }
  },
  email: {
    placeholder: {
      en: "EMAIL*",
      de: "EMAIL*"
    },

    invalid: {
      en: "Please check your Email",
      de: "Bitte prüfen Sie Ihre Email"
    }
  },
  message: {
    placeholder: {
      en: "MESSAGE*",
      de: "NACHRICHT*"
    },

    invalid: {
      en: "Please write your message",
      de: "Bitte schreiben Sie uns Ihre Nachricht"
    }
  },
  send: {
    en: "Get in touch",
    de: "Anfrage stellen"
  },
  connects: {
    title: {
      en: "How to contact us:",
      de: "So erreichen Sie uns:"
    }
  }
};
