const stickyLogo = document.getElementsByClassName("nav-sticky__logo")[0];
const stickyButton = document.getElementsByClassName("nav-sticky__button")[0];
const stickyLang = document.getElementsByClassName("nav-sticky__lang")[0];
const stickyNav = document.getElementsByClassName("nav-sticky__nav")[0];

window.addEventListener("scroll", function () {
  if (window.pageYOffset > document.getElementsByTagName("header")[0].clientHeight) {
    stickyLogo.classList.add("nav-sticky__logo--show");
    stickyButton.classList.add("nav-sticky__button--show");
    stickyLang.classList.add("nav-sticky__lang--scroll");
    stickyNav.classList.add("nav-sticky__nav--scroll");
  } else {
    stickyLogo.classList.remove("nav-sticky__logo--show");
    stickyButton.classList.remove("nav-sticky__button--show");
    stickyLang.classList.remove("nav-sticky__lang--scroll");
    stickyNav.classList.remove("nav-sticky__nav--scroll");
  }
});
