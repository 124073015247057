document.querySelectorAll(".feedback-form__input--textarea").forEach((el) => {
  el.setAttribute("style", "height:" + el.scrollHeight + "px;overflow-y:hidden;");
  el.oninput = () => {
    el.style.height = el.scrollHeight + "px";
  };
});

document.querySelectorAll("img[data-logo]").forEach((el) => {
  el.onclick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
});

document.querySelector(".scroll-top").onclick = () => {
  window.scrollTo(pageYOffset, 0);
};

window.onscroll = function () {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const scrolled = (winScroll / height) * 100;
  const top = window.scrollY;
  const scrollHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
  const scrollTopButton = document.querySelector(".scroll-top");
  if (window.scrollY >= scrollHeight - innerHeight - 112 && innerWidth > 768) scrollTopButton.style.bottom = "11em";
  else scrollTopButton.style.bottom = "1.7em";
  if (top >= 20) {
    scrollTopButton.style.opacity = 1;
  } else {
    scrollTopButton.style.opacity = 0;
  }
  document.getElementById("myBar").style.width = scrolled + "%";
};
