export default {
  error: {
    title: {
      en: "Error!",
      de: "Fehler!"
    },
    message: {
      en: "Something went wrong",
      de: "Etwas ist schief gelaufen"
    },
    button: {
      en: "Try again",
      de: "Versuchen es erneut"
    }
  },
  phone: {
    en: "Phone:",
    de: "Telefon:"
  },
  menu: {
    en: "Menu",
    de: "Menu "
  },
  open: {
    en: "Open",
    de: "Öffnen"
  },
  details: {
    en: "More information",
    de: "Mehr erfahren"
  }
};
