export default {
  headline: {
    en: "Industries",
    de: "Branchen"
  },
  title: {
    en: "Our solutions offer added value across all industries!",
    de: "Unsere Lösungen bieten branchenübergreifend einen Mehrwert!"
  },
  description: {
    en: "Elevate your business with immersive digitization approaches!",
    de: "Bringen Sie Ihr Unternehmen mit immersiven Digitalisierungsansätzen nach vorne!"
  },
  b2b: {
    title: {
      en: "B2B",
      de: "B2B"
    },
    text: {
      en: "Expand conventional office mechanics with VR / AR / 360 ° and tailor-made web design! Example: VR conferences, immersive websites and more!",
      de: "Erweitern Sie herkömmliche Büromechaniken durch VR/AR/360° und maßgeschneidertes Webdesign! Beispiel: VR-Konferenzen, immersive Websites und mehr!"
    }
  },
  education: {
    title: {
      en: "Education",
      de: "Bildung"
    },
    text: {
      en: "VR and AR in particular add unprecedented added value to school and industrial training, further education and training! Our solutions can visualize processes, locations and scenarios at any time and interact with them!",
      de: "Insbesondere VR und AR fügen der schulischen wie auch innerbetrieblichen Aus-, Fort und Weiterbildung beispiellosen Mehrwert hinzu! Unsere Lösungen können Prozesse, Orte und Szenarien jederzeit visualisieren und mit ihnen interagieren!"
    }
  },
  engineering: {
    title: {
      en: "Engineering",
      de: "Industrie"
    },
    text: {
      en: "Visualize industrial machines and systems as well as their processes - regardless of location! - and enjoy completely new perspectives of the product, sales and support training courses as well as troubleshooting and more!",
      de: "Visualisieren Sie Großanlagen und deren Abläufe – standortunabhängig! – und genießen Sie so gänzlich neue Perspektiven der Produkt-, Vertriebs- und Supportschulungen sowie Aus- und Fortbildung, Troubleshooting und mehr!"
    }
  },
  gaming: {
    title: {
      en: "Gaming",
      de: "Videospiele"
    },
    text: {
      en: `Whether games for the steadily growing VR market or the "gamification" of serious content with educational purpose – gaming and VR/AR are knotted!`,
      de: "Ob Spiele für den stetig wachsenden VR-Markt oder oder die „Gamification“ von Lehrinhalten – die spielerische Komponente ist mit dem VR-/AR-Thema eng verbunden!"
    }
  },
  architecture: {
    title: {
      en: "Architecture and real estate",
      de: "Architektur- und Immobilienbranche"
    },
    text: {
      en: "Walk through, design, furnish and sell houses or other objects - before the groundbreaking ceremony has even taken place! VR and 360 ° solutions in particular create an immense added value in this field!",
      de: "Objekte begehen, gestalten, einrichten und verkaufen – vor dem ersten Spatenstich! Insbesondere VR- und 360°-Lösungen trumpfen in diesem Feld auf!"
    }
  },
  marketing: {
    title: {
      en: "Marketing & Sales",
      de: "Marketing & Sales"
    },
    text: {
      en: "Expand the tool set of marketing and sales with the component of making them tangible: location-independent product presentations, sales meetings, actual sales and more – in VR!",
      de: "Erweitern Sie das Instrumentarium von Marketing und Sales durch die Komponente des Erlebbarmachens: Standortunabhängige Produktpräsentationen, Sales Meetings, Kaufabschlüsse und mehr in VR!"
    }
  },
  tourism: {
    title: {
      en: "Tourism",
      de: "Tourismus"
    },
    text: {
      en: "Bringing places to life at the push of a button and getting a realistic feeling for them - a great benefit also in the tourism industry!",
      de: "Orte auf Knopfdruck erlebbar machen und ein realitätsnahes Gefühl für selbige bekommen – ein großer Gewinn auch in der Tourismusbranche!"
    }
  }
};
