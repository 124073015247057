export default {
  vr: {
    en: "Virtual reality environments enable you to find yourself in desired locations or in desired scenarios regardless of location and to interact with your colleagues, employees and partners within the virtual environment as well as with the environment itself! Enhance your business with Virtual Reality!",
    de: "Virtual-Reality-Umgebungen befähigen Sie, sich standortunabhängig an Wunschorten oder in Wunschszenarien wiederzufinden und innerhalb der virtuellen Umgebung mit Ihren Kollegen, Mitarbeitern und Partnern ebenso zu interagieren wie mit der Umgebung selbst! Erweitern Sie Ihr Business um die virtuelle Realität!"
  },
  game: {
    en: "Virtual reality games open up new worlds of discovery and an unprecedented gaming experience for everyone!",
    de: "Virtual-Reality-Games eröffnen neue Welten des Entdeckens und ein nie dagewesenes Spielerlebnis für jung und alt!"
  },
  grad360: {
    en: "We create custom-made immersive 360 ​​° content! Would you like an interactive 360 ​​° representation of your company, district or site of any kind - whether indoor or outdoor? We are your full-service partner for 360 ° photo and video production!",
    de: "Wir kreieren immersive 360°-Inhalte nach Maß! Sie wünschen eine interaktive 360°-Darstellung Ihres Unternehmens, Stadtteils oder Gelände jeglicher Art – egal ob Indoor oder Outdoor? Wir sind Ihr Full-Service-Partner für 360°-Foto- und Videoproduktion!"
  },
  web: {
    en: "Tailor-made web design! In addition to virtual worlds, we also build your website for you! Implemented interaction options as well as WebVR modules for the immersive visualization of places, buildings, processes and much more make your web presence literally an experience!",
    de: "Webdesign nach Maß! Neben virtuellen Welten bauen wir Ihnen auch Ihre Internetpräsenz! Implementierte Interaktionsmöglichkeiten sowie WebVR-Module zur immersiven Visualisierung von Orten, Gebäuden, Prozessen u.v.m. machen Ihren Web-Auftritt buchstäblich zu einem Erlebnis!"
  },
  hybrid: {
    en: "Combine our state-of-the-art technologies in order to set up your brand world or your use case professionally and attractively for the customer! Virtual conference rooms for location-independent yet personal meetings, sales and product training, troubleshooting and much more!",
    de: "Kombinieren Sie unsere State-of-the-Art-Technologien, um Ihre Markenwelt oder Ihren Use Case professionell und kundenseitig attraktiv zu gestalten! Virtuelle Konferenzräume für standortunabhängige und dennoch persönliche Meetings, Vertriebs- und Produktschulungen sowie Troubleshooting u.v.m.!"
  }
};
